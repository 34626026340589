import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const es = {
  title: "Simple Commerce",
  home: {
    metaTitle: TITLE,
    metaDescription:
      "Disfruta de las mejores películas y snacks. Solo en Multicines encontrarás lo que buscas."
  },
  highTraffic: {
    title1: "Espera mientras aseguramos tu asiento",
    content1:
      "Estás a punto de realizar tu compra para la película que tanto esperabas. Gracias por tu paciencia mientras procesamos tu solicitud",
    title2: "Que la función continue",
    content2:
      "Ahora puedes seguir con tu compra y prepararte para disfrutar de tu película",
    buttonContent: "Continuar compra"
  },
  footer: {
    titles: {
      account: "Cuenta",
      data: "Datos",
      contact: "Contacto"
    },
    copyright: "Todos los derechos reservados.",
    develop: "Desarrollado por",
    navigation: {
      birthday: "Cumpleaños",
      events: "Eventos especiales",
      coupons: "Cupones corporativos",
      ads: "Publicidad",
      services: "Servicios corporativos",
      snacks: "Dulcería",
      multidrive: "Multidrive In",
      vip: "Salas VIP",
      service: "Servicios innovadores",
      billing: "Facturación",
      terms:
        "Política de privacidad Ley Orgánica de Protección de datos personales",
      faq: "Preguntas frecuentes",
      us: "Nosotros",
      aboutUs: "Sobre nosotros",
      workUs: "Trabaja con nosotros",
      contact: "Contáctanos",
      legals: "Legales",
      whatsapp: "Whatsapp",
      firstIssue: "Primera emisión de obligaciones Multicines S.A.",
      extendFirst: "Prórroga primera emisión de obligaciones Multicines S.A",
      secondIssue: "Segunda emisión de obligaciones Multicines S.A."
    }
  },
  productsSearch: {
    metaTitle: "Descubre"
  },
  loyaltyWarningScreen: {
    title: "Existe una cuenta asociada a la cédula ",
    description:
      "Existe una cuenta asociada al número de cédula ingresado, por favor verifica e ingresa con la cuenta correspondiente",
    logout: "Salir e ingresar con otra cuenta",
    needHelp: "¿Necesitas ayuda?",
    support: "Contactar con soporte",
    continueWithoutMulticlub: "Continuar sin programa de lealtad Multiclub"
  },
  coupons: {
    metaTitle: "Cupones",
    detailTitle: "Cupones",
    available: "Disponibles",
    haveCoupon: "¿Tienes un cupón?",
    enterCoupon: "Ingresa tu cupón",
    empty: "¡Agrega un cupón a tu billetera!",
    emptyDescription:
      "Aún no has agregado cupones a tu billetera, intenta canjear un código para obtener cupones"
  },
  profile: {
    metaTitle: "Usuario",
    edit: {
      title: "Edita tu perfil",
      change: "Cambiar contraseña",
      successful: "Datos actualizados exitosamente",
      deleteAccount: "Eliminar cuenta",
      save: "Guardar cambios"
    },
    signIn: "Iniciar sesión",
    signUp: "Registrarse",
    cards: {
      title: "Tarjetas guardadas",
      addButton: "Añadir",
      emptyTitle: "Guarda tus tarjetas",
      emptyDescription:
        "Agrega una tarjeta para realizar tus compras aún más rápido",
      errorConfirmation: "No olvides aceptar los términos y condiciones"
    },
    billing: {
      title: "Datos de facturación",
      empty: "¡Agrega tus datos de facturación!",
      emptyButton: "Nuevos datos de facturación",
      saved: "Datos guardados",
      maxBilling: {
        possible: "Solo es posible tener",
        active: "datos de facturación activos",
        limitation: "Límite de datos de facturación superado"
      },
      invalidPhone: "El teléfono ingresado no es válido",
      updateErrorData:
        "Ocurrió un problema al actualizar el dato de facturación",
      saveErrorData: "Ocurrió un problema al guardar el dato de facturación"
    },
    wishlist: {
      tickets: "Tus entradas",
      all: "Ver todos"
    },
    ticket: {
      title: "Entradas",
      tickets: "Tus entradas",
      all: "Ver todos"
    },
    notification: {
      title: "Notificaciones",
      activateNotifications: "Acepto recibir correos electrónicos",
      empty: "Aquí se mostrarán las notificaciones de tus reservas",
      emptyButton: "Explorar películas"
    },
    order: {
      metaTitle: "Historial de compra",
      empty: "¡Realiza tu primera compra!"
    },
    ticketsCarousel: {
      startIn: "Comienza en",
      details: "Ver detalles"
    },
    orderCard: {
      help: "Ayuda",
      details: "Ver detalles",
      assisted: "Asistida",
      toBeAssisted: "Por asistir",
      canceled: "Cancelada",
      rejected: "Rechazado",
      approved: "Exitoso",
      pending: "Procesando",
      previous: "Compras procesadas",
      lastPurchases: "Compras en proceso"
    },
    orderDetails: {
      instructions: "Retira tu orden mostrando el código QR",
      seats: "Asientos"
    },
    orderHistory: {
      title: "Historial de compras",
      empty: "Aún no has comprado entradas",
      emptyMessage:
        "¿Qué esperas? Explora nuestra cartelera y haz tu primera compra."
    },
    terms: "Términos legales",
    support: "Soporte"
  },
  filters: {
    title: "Filtrar",
    filter: "Filtros",
    all: "Todo",
    premiere: "Estreno",
    presale: "Pre-venta",
    preview: "Próximamente",
    view: "Ver",
    languages: "Idiomas",
    type: "Tipo de sala",
    format: "Formato de películas",
    spa: "Español",
    sub: "Subtitulado",
    billboard: "Cartelera",
    now: "Ahora",
    nextFunction: "La siguiente función comienza en"
  },
  movie: {
    premiere: "Estreno",
    presale: "Pre-venta",
    exclusivePresale: "Pre-venta exclusiva",
    age: "años",
    preview: "Próximamente",
    allPublic: "Todo público",
    withSupervision: "bajo supervisión",
    emptyMessage: "No hay funciones disponibles para esta sala en esta fecha",
    emptyButton: "Buscar la siguiente fecha disponible",
    availableSeats: "Asientos disponibles",
    selectCinema: "Selecciona el complejo",
    screen: "Pantalla",
    saveReservation: "¿Quieres guardar tu proceso de reserva?",
    exitMessage:
      "Puedes guardar tu proceso y continuar luego, si abandonas perderás tus reservas",
    continueReservation: "Continuar con la reserva",
    saveAndContinueLater: "Guardar y continuar luego",
    exit: "Abandonar proceso",
    pointsRestriction: "Restricción de puntos",
    today: "Hoy",
    available: "Disponibles",
    unavailable: "No disponible",
    depleted: "Agotado",
    emptyBillboard: "Estamos renovando la cartelera",
    emptyBillboardMessage:
      "Intenta cambiar de complejo para ver otras carteleras disponibles",
    cancelReservationError: "Falló la cancelación, inténtalo nuevamente",
    emptyStores: "No hay complejos disponibles",
    emptyStoresMessage:
      "Al momento no hay complejos disponibles vuelve a intentar más tarde.",
    theater: "Sala",
    screenings: "Funciones",
    trailers: "Trailers",
    information: "Información",
    nextAvailableDate: "Buscar siguiente fecha disponible",
    synopsis: "Sinopsis",
    noSynopsis: "Sin sinopsis",
    actors: "Reparto",
    seeAll: "Ver todos",
    seeLess: "Ver menos",
    selectSchedule: "Selecciona tu horario",
    allLanguages: "Todos los idiomas",
    allRooms: "Todas las salas",
    allFormats: "Todos los formatos",
    ticketDetails: "Detalle de tus boletos",
    gallery: "Galería",
    noTrailers: "No hay trailers disponibles",
    previewTitle: "¡Próximo estreno!",
    previewDescription: "Muy pronto esta película estará en cartelera",
    emptyMovieDescription:
      "No hay funciones disponibles para esta película en esta fecha"
  },
  navigation: {
    shopping: "Compras",
    discover: "Descubre",
    loyalty: "Lealtad",
    coupons: "Cupones",
    profile: "Perfil",
    snacks: "Dulcería",
    movies: "Cartelera",
    spotlight: "Inicio",
    multiclub: "Multiclub",
    login: "Ingresar",
    logout: "Salir",
    Navbar: {
      selectCinema: "Seleccione un complejo"
    }
  },
  catalogues: {
    delivery: "Entrega",
    pickup: "Retiro",
    other: "Otro"
  },
  tickets: {
    minError: "Elige al menos un ticket",
    empty: "Estamos contando las entradas",
    title: "Tus boletos",
    selectTickets: "Selecciona tus boletos",
    notEnoughPoints: "Sin puntos suficientes",
    cantAddMore: "No puedes agregar más",
    info: "Se solicitará su cédula de identidad para ingresar a la función"
  },
  downloadBanner: {
    title: "Lleva la experiencia del cine ahora en tus manos",
    downloadApp: "Descargar app",
    download: "Descargar"
  },
  common: {
    person: "persona",
    applyFor: "Aplicar para: ",
    invalidUser: "Usuario inválido",
    invalidSession: "Pelicula inválida",
    understood: "Entendido",
    vistaError: "Vista: Servicio no disponible",
    errorSelectedCityStore: "Selecciona un complejo para continuar",
    dateAndHour: "Fecha y hora",
    preferences: "Preferencias",
    workflows: "Modalidad de compra",
    apply: "Aplicar",
    buyingIn: "Comprando en",
    catalogue: "Catálogo",
    continue: "Continuar",
    retry: "Reintentar",
    send: "Enviar",
    confirm: "Confirmar",
    cancel: "Cancelar",
    edit: "Editar",
    soon: "¡Prepárate, esta funcionalidad estará disponible próximamente!",
    goToPay: "Ir a pagar",
    pay: "Pagar",
    emailAddress: "Correo electrónico",
    warning: "¿Estás seguro que quieres salir?",
    errorTitle: "Algo salió mal",
    errorMessage:
      "Si el problema sigue comunícate con soporte y te ayudaremos a solucionarlo.",
    add: "Añadir",
    update: "Actualizar",
    accept: "Aceptar",
    save: "Guardar",
    change: "Cambiar",
    delete: "Eliminar",
    keep: "No eliminar",
    changeSession: "Cambiar de horario",
    changeCinema: "Cambiar de complejo",
    emptyMessage:
      "Intenta cambiar de horario para ver otras entradas disponibles",
    emptyAction:
      "Intenta cambiar de complejo para ver otras opciones de menú o vuelve a intentar más tarde.",
    goBack: "Regresar",
    remainingTime: "Tiempo restante",
    tryAgain: "Volver a intentarlo",
    buyTickets: "Compra tus boletos",
    trailer: "Ver trailer",
    numberOrder: "Nro de orden.",
    store: "Complejo",
    billing: "Facturación",
    email: "Correo",
    paymentMethod: "Método de pago"
  },
  forms: {
    name: "Nombre",
    lastName: "Apellido",
    nameAndLastName: "Nombre y apellido",
    documentType: "Tipo de documento",
    document: "Documento de identidad",
    documentNumber: "Número de documento",
    email: "Correo electrónico",
    emailAddress: "Correo electrónico",
    emailPlaceholder: "correo@correo.com",
    address: "Dirección",
    addAddress: "agregar nueva dirección",
    phone: "Número de teléfono",
    password: "Contraseña",
    cardNumber: "Número de la tarjeta",
    cardHolder: "Nombre de la tarjeta",
    expirationDate: "Fecha de vencimiento",
    cvv: "CVV",
    dateOfBirth: "Fecha de nacimiento",
    dateOfBirthPlaceholder: "DD / MM / AAAA",
    passport: "Pasaporte",
    ci: "Cédula",
    businessName: "Razón social",
    invalidDateOfBirth: "Fecha de nacimiento no válida"
  },
  errors: {
    invalidUser: "Usuario inválido",
    storeNotFound: "Complejo no encontrado",
    generic: "Algo salió mal",
    invalidDocument: "Número de documento inválido",
    validationError: "Error de validación",
    purchaseFail: "El proceso se ha abandonado",
    google: {
      canceled: "Intento de autorización cancelado",
      token: "Algo salió mal obteniendo el token de acceso",
      playServicesNotAvailable:
        "Los servicios de Google no están disponibles o están desactualizados",
      inProgress:
        "Se está intentando invocar otra operación mientras la anterior todavía no ha finalizado",
      signInRequired: "Ningún usuario ha iniciado todavía"
    },
    facebook: {
      cancel: "Intento de autorización cancelado",
      token: "Algo salió mal obteniendo el token de acceso"
    },
    apple: {
      canceled: "Intento de autorización cancelado",
      token:
        "El inicio con Apple ha fallado - no se ha devuelto un identify token",
      failed: "El intento de autorización ha fallado",
      invalid: "La solicitud de autorización recibió una respuesta inválida",
      notHandled: "La solicitud de autorización no fue controlada",
      unknown: "El intento de autorización ha fallado por una razón desconocida"
    },
    firebase: {
      auth: {
        wrongPassword: "Los datos son inválidos",
        userNotFound:
          "No existe un registro de usuario con el correspondiente identificador provisto",
        tooManyRequests:
          "Demasiadas solicitudes. El acceso a esta cuenta se ha inhabilitado temporalmente",
        emailAlreadyInUse: "El correo electrónico provisto ya está en uso",
        networkRequestFailed:
          "Ha ocurrido un error de conexión, intenta nuevamente",
        operationNotAllowed:
          "El proveedor está deshabilitado en tu proyecto de firebase",
        accountExistsWithDifferentCredential:
          "An account already exists with the same email address but different sign-in credentials",
        invalidActionCode: "El link usado es inválido"
      }
    },
    shoppingCart: {
      createCart: "No se pudo crear el carrito",
      addToCart: "Falló en agregarse el producto, inténtalo nuevamente"
    },
    tickets: {
      max: "Disponibilidad de la sala",
      reached: "alcanzada",
      deeplink:
        "Error al cargar la información del enlace, vuelva a intentarlo nuevamente"
    },
    seats: {
      full: "Deselecciona un asiento para seleccionar otro",
      unavailable: "Asientos no permitidos para esta zona"
    },
    fallback: "La petición falló, inténtelo nuevamente",
    support:
      "Ya eres socio MultiClub, escribe a serviciocliente@multicines.com.ec con tu cédula y correo para actualizarlo",
    loyaltyCommonError:
      "Verifica que la cédula y la fecha de nacimiento que ingresaste sean válidos."
  },
  checkout: {
    title: "Checkout",
    sso: "Puedes ingresar con tu cuenta",
    personalInfo: "Información personal",
    paymentMethod: "Método de pago",
    cash: "Efectivo",
    card: "Tarjeta",
    loyalty: "Lealtad",
    pay: "Pagar",
    acceptTerms: "Acepto los ",
    terms: "términos y condiciones y políticas de privacidad de datos",
    faq: "Preguntas frecuentes",
    BillingSection: {
      billingTitle: "Información personal",
      billingInfo: "Datos de facturación",
      addBilling: "Añade datos de facturación",
      sameBilling: "Usar los mismos datos para la facturación",
      noSelectedTitle: "Selecciona la facturación",
      noSelectedDescription:
        "Por favor selecciona la facturación para continuar"
    },
    addCard: "+ Añadir nueva tarjeta",
    chooseCard: "Elegir tarjeta",
    changeCard: "Cambiar tarjeta",
    selectedCardError:
      "La tarjeta seleccionada no podrá ser utilizada en esta compra, te recomendamos cambiarla"
  },
  purchaseStatus: {
    orderCreation: "Estamos procesando tu orden...",
    buildingOrderError: "No se pudo procesar tu orden",
    paymentValidation: "Estamos validando tu pago",
    success: "¡Compra realizada con éxito!",
    p2pSuccess: {
      reference: "Tu pago con número de referencia",
      value: "y valor",
      approved: "fue aprobado."
    },
    p2pPending: {
      reference: "Tienes un pago pendiente con número de referencia",
      value: "y valor"
    },
    p2pRejected: {
      reference: "Tu pago con número de referencia",
      value: "y valor",
      rejected: "fue rechazado."
    },
    pendingPayment:
      "El usuario tiene un pedido pendiente de pago con número de referencia ",
    pendingValidation:
      "se encuentra pendiente. La tarjeta se aprobará cuando se valide el cobro, el cual sera devuelto inmediatamente.",
    errorTitle: "Hubo un error al procesar la orden",
    errorDescription:
      "No se pudo procesar la orden debido a un error, vuelve a intentarlo",
    approved: "aprobada",
    rejected: "rechazada",
    pending: "pendiente",
    cardError: "La suscripción de tarjeta con No. de referencia",
    errorValidation: "se ha rechazado",
    card: "Tarjeta",
    addedCard: "Tarjeta agregada con éxito",
    successCard: {
      subscription: "La suscripción de la tarjeta con No. de referencia",
      hasBeen: "ha sido",
      charge: "Hemos generado un cobro de verificación de",
      refund: "este monto será reembolsado en unos minutos"
    }
  },
  auth: {
    signIn: "Iniciar sesión",
    signUp: "Registrarse",
    AuthNotification: {
      benefits: "",
      signIn: "Ingresa con tu cuenta y disfruta de beneficios"
    },
    ChooseAuth: {
      omit: "Omitir",
      title: "Regístrate y disfruta de increíbles beneficios",
      alternativeSignIn: "O ingresa usando tu correo",
      alternativeSignUp: "O regístrate usando tu correo",
      email: "Usar correo electrónico"
    },
    SSOButton: {
      continue: "Continuar con"
    },
    MagicLink: {
      titleSignIn: "Ingresa con tu correo electrónico",
      titleSignUp: "Regístrate con tu correo electrónico",
      signInContinue: "Ingresar",
      signUpContinue: "Registrarse",
      alternativeSignIn: "¿No tienes cuenta?",
      alternativeSignUp: "¿Ya tienes cuenta?",
      signIn: "Crea una cuenta",
      signUp: "Ingresa a tu cuenta"
    },
    CheckEmailVerification: {
      title: "Revisa tu correo electrónico",
      instructions: "Haz click en el link que enviamos al correo",
      instructions2: "para ingresar a tu cuenta",
      help: "Si no has recibido nada a tu correo revisa tu bandeja de SPAM, o puedes",
      tryAgain: "Volver a solicitar el correo"
    },
    SecondStep: {
      title: "Queremos saber un poco más de ti",
      continue: "Crear cuenta"
    },
    SignInConfirmation: {
      title: {
        loading: "Ingresando...",
        success: "Ingreso exitoso",
        error: "Algo salió mal al intentar ingresar"
      }
    }
  },
  global: {
    distanceAlert: {
      title: "Estás un poco lejos del cine seleccionado"
    },
    especialInfo:
      "Al comprar boletos de discapacidad o tercera edad se solicitará su cédula de identidad para ingresar a la función.",
    corporativeMessage:
      "Los códigos corporativos con valor $0 ya fueron prepagados"
  },
  seats: {
    empty: "Estamos limpiando la sala",
    Seats: {
      incompleteSeatsError: "Selecciona tus asientos",
      select: "Tus asientos",
      reservationError: "Falló la reserva, inténtalo nuevamente",
      reservationVistaError: "Vista: ",
      seatsScroll:
        "Desliza a los lados para ver todas las secciones de asientos",
      alreadyRedeemed:
        "El boleto corporativo que deseas canjear ya ha sido redimido"
    },
    SeatsSelection: {
      reserved: "Reservado",
      available: "Disponible",
      selected: "Seleccionado",
      distancing: "Distanciamiento"
    },
    SeatsCategory: {
      tickets: "Boletos"
    },
    detail: "Detalles de tus boletos"
  },
  orderDetails: {
    BillingSummary: {
      billing: "Facturación",
      paymentMethod: "Método de pago",
      transactionDate: "Fecha de la transacción",
      transactionTime: "Hora de la transacción",
      reference: "Referencia",
      status: "Estado",
      orderCode: "Código de orden",
      paymentStatus: "Estado del pago",
      cardPayment: "Pago con tarjeta",
      pointsPayment: "Canje de puntos",
      auth: "Número de autorización",
      multipower: "Multipower",
      corporativePayment: "Boleto corporativo"
    },
    OrderDetails: {
      instructions: "Retira tu orden mostrando el código QR",
      seats: "Asientos",
      share: "Compartir",
      download: "Descargar",
      info: "Puedes presentar una captura de pantalla en caso de tener problemas de conexión"
    },
    OrderSummary: {
      each: "c/u",
      total: "Total"
    }
  },
  categories: {
    title: "Categorías",
    empty: "Estamos cambiando el menú",
    emptyProducts: "No hay productos",
    emptyDescription:
      "Al parecer no hay productos aquí, intenta explorando otras categorías",
    emptyButton: "Explorar",
    error: "Al parecer algo falló",
    errorDescription:
      "Hubo un error al cargar la página, intenta recargando la página o vuelve luego",
    errorButton: "Explorar más productos"
  },
  greetings: {
    simple: "disfruta lo mejor del cine",
    snacks: "Disfruta los mejores snacks",
    profile: "Hola",
    discover: "Descubre las mejores películas",
    billboard: "Ver cartelera",
    profileAnonymous: "Ingresa con tu cuenta",
    profileAnonymousDescription:
      "Ingresa para crear listas, ver tu historial de compras y disfrutar de todos los beneficios exclusivos que tenemos para ti."
  },
  cart: {
    title: "Carrito",
    tickets: "Boletos",
    snacks: "Dulcería",
    subtotal: "Subtotal",
    total: "Total",
    taxes: "Impuestos",
    add: "Añadir al carrito",
    addSnacks: "Agregar snacks",
    exploreSnacks: "Explorar más snacks",
    empty: "¡Prepárate para vivir la mejor experiencia!",
    emptyMessage:
      "Escoge tu película favorita, agrega snacks a tu orden y disfruta lo mejor de Multicines",
    confirmStore: "Confirmar mi compra en ",
    errorStore: "No olvides confirmar la tienda",
    ticketsDetail: "Ver detalle de tu orden",
    refund: "Una vez realizada la compra no se acepta cambio o devoluciones",
    dinersAdvisement:
      "*Pago disponible con tarjeta Diners Club, no aplica para tarjetas Titanium y Discover"
  },
  selectDate: {
    when: "¿Cuándo quieres ver tu película?",
    upcomingReleases: "Próximos estrenos",
    emptyButton: "Ver cartelera"
  },
  changeStore: {
    changeCinema: "Cambiar cine",
    title: "Al cambiar de cine perderás los productos de tu carrito",
    description:
      "Deberás agregar nuevos productos a tu carrito para continuar con tu compra",
    keepCart: "Permanecer en ",
    changeLocation: "Cambiar a "
  },
  membership: {
    title: "Multiclub",
    metaTitle: "Lealtad",
    memberSince: "Fecha de aniversario: ",
    CurrentLevel: {
      missing: {
        left: "Te faltan",
        visit: "visita",
        next: "para pasar al siguiente nivel"
      },
      benefits:
        "Disfruta de los beneficios de ser uno de nuestro clientes premium",
      historyText: "Historial de movimientos",
      visits: "Visitas",
      soon: "Pronto estará disponible"
    },
    LevelCard: {
      visits: "Visitas al año",
      current: "Actual",
      titleBenefits: "Beneficios disponibles",
      instructions: "Disfruta de los beneficios de tu nivel actual",
      bronze: "Bronce",
      silver: "Plata",
      gold: "Oro"
    },
    MembershipLevel: {
      title: "Membresía",
      noPoints: "Gana puntos con tus visitas",
      visitsText: "Puntos",
      otherLevels: "Otros niveles"
    },
    terms: "Términos y Condiciones de Multiclub",
    ProfileNoMembership: {
      title: "Ingresa con tu cuenta de Multiclub ",
      instructions:
        "Descuentos, acumulación y canje de Multipuntos, promociones y eventos exclusivos",
      join: "Ingresar ahora"
    },
    SignInModal: {
      needSignUp: "¿Aún no eres miembro Multiclub?",
      signUp: "Crea tu membresía",
      title: "¡Que bueno tenerte de vuelta!",
      instructions:
        "Ingresa con el correo y contraseña que utilizaste para tu membresía Multiclub",
      link: "Olvidé mi contraseña",
      button: "Ingresar a Multiclub",
      mutationError: "Falló la conexión de cuentas, inténtalo nuevamente"
    },
    SignUpModal: {
      needSignIn: "¿Ya tienes membresía Multiclub?",
      signIn: "Ingresa con tu cuenta",
      title: "disfruta de tus beneficios de Multiclub",
      instructions:
        "Completa tus datos para ingresar a la familia de Multiclub",
      button: "Unirme a Multiclub",
      mutationError: "Falló el registro, inténtalo nuevamente",
      termsPre: "Acepto los",
      terms: "términos y condiciones",
      termsPost: "del plan Multiclub",
      benefits: "Afíliate como socio Multiclub"
    },
    ProfileMembership: {
      level: "Nivel",
      visits: "Visitas",
      check: "Ver membresía",
      title: "Membresía"
    },
    MembershipNotification: {
      title: "Disfruta de los beneficios Multiclub",
      join: "Unirme"
    },
    ChooseMembership: {
      title: "Descubre los beneficios de ser Socio Multiclub",
      instructions:
        "Descuentos, acumulación y canje de Multipuntos, promociones y eventos exclusivos",
      signIn: "Ya soy miembro Multiclub",
      join: "Unirme como un nuevo miembro"
    },
    Confirmation: {
      title: {
        loading: "Ingresando...",
        success: "Ingreso exitoso",
        error: "Algo salió mal al intentar ingresar"
      }
    },
    RecoverPasswordSection: {
      title: "Recuperar tu contraseña",
      description: "Ingresa tu correo para recuperar tu contraseña",
      successTitle: "Correo enviado",
      successMessage:
        "Un correo fue enviado a la dirección de correo electrónico provisto"
    },
    Verification: {
      title: "Hemos enviado un correo a",
      instructions: "Verifica tu correo e inicia sesión",
      noResponse: "¿No recibiste el correo?",
      resend: "Volver a enviar"
    },
    ResetPassword: {
      title: "Crea tu nueva contraseña",
      repeat: "Repite la nueva contraseña",
      create: "Crear nueva contraseña",
      instructions:
        "Para acceder nuevamente a tu cuenta, ingresa una nueva contraseña fuerte"
    },
    error: {
      title: "¡Tenemos un problema con el usuario, comunícate con soporte!",
      button: "Ir a inicio"
    }
  },
  support: {
    formTitle: "Resuelve tus dudas y consultas llamando al ",
    formTitle2: " o déjanos tu comentario en el siguiente formulario:",
    names: "Nombres",
    lastNames: "Apellidos",
    document: "Documento de identidad",
    information: "El documento puede ser cédula, pasaporte o RUC",
    selectStore: "Selecciona un complejo",
    store: "Complejo",
    movie: "Película o función",
    date: "Fecha de visita",
    claimType: "Tipo de consulta",
    comments: "Comentarios",
    comment: "Comentario",
    addComment: "Añade un comentario",
    error:
      "El identificador único de usuario o el correo electrónico no están definidos",
    errorMessage: "Se produjo un error al intentar enviar tu mensaje",
    successMessage: "Mensaje enviado exitosamente",
    invalidDate: "Fecha inválida",
    subjectType: "Tipo de consulta",
    subject: "Comentarios del cliente"
  },
  timeout: {
    title: "El tiempo de tu reserva ha expirado",
    description:
      "El tiempo de tu reserva ha expirado, intenta realizar nuevamente la reserva de tus boletos",
    goToBillboard: "Ir a la cartelera",
    backToMovie: "Volver a la película"
  },
  location: {
    to: "A",
    from: "de ti"
  },
  politics: {
    title: "Te invitamos a revisar las nuevas políticas y aceptarlas.",
    description:
      "En MULTICINES S.A. tratamos la información que nos facilita con la finalidad de gestionar iniciativas comerciales y relación con nuestros clientes. Usted tiene derecho al acceso, actualización, revocación, eliminación y oposición al tratamiento de sus datos. Si quiere ejercer su derecho envíenos un correo electrónico a: privacidad@multicines.com.ec",
    termsPre: "He leído y acepto el",
    dataTerms: "Tratamiento de uso de datos",
    required: "* Esta opción es obligatoria",
    terms:
      "Acepto el envío de iniciativas comerciales basadas en mis gustos, preferencias y consumos, sobre productos y servicios de MULTICINES por cualquier medio. Podré cancelar mi suscripción en cualquier momento.",
    multiclubBenefit:
      "Activa este campo para recibir beneficios exclusivos como socio MultiClub"
  },
  signUpForm: {
    title: "Queremos saber un poco más de ti.",
    terms: "He leído y acepto el Tratamiento de uso de datos.",
    privacy:
      "Acepto el envío de iniciativas comerciales basadas en mis gustos, preferencias y consumos, sobre productos y servicios de MULTICINES por cualquier medio. Podré cancelar mi suscripción en cualquier momento.",
    createAccount: "Crear cuenta",
    invites: "¿No quieres llenar tus datos?",
    continueButton: "Continuar como invitado",
    multiclubBenefit:
      "Activa este campo para recibir beneficios exclusivos como socio MultiClub"
  },
  deleteAccount: {
    errorDeleted: "No se pudo eliminar la cuenta",
    title: "Eliminar cuenta",
    question: "¿Seguro deseas eliminar tu cuenta?",
    description:
      "Una vez que elimines tu cuenta no podrás volver a recuperarla y deberás crear una cuenta nueva.",
    cancel: "Mantener mi cuenta",
    delete: "Eliminar mis datos",
    cancelTitle: "Que la función continue",
    cancelDescription: "Compartir es felicidad",
    deleteTitle: "Tu cuenta ha sido eliminada",
    deleteDescription: "Te extrañaremos, esperamos volver a verte pronto."
  },
  repeatOrder: {
    noLastSnackOrder: "No hay orden de snacks para repetir",
    noCoordinates: "No hay coordenadas seleccionadas",
    noRebuildCart: "No se pudo repetir la orden de snacks",
    noValidateShoppingCart: "No se pudo validar el carrito de compras",
    repeatLastSnackOrder: "¿Quieres repetir tu última compra de snacks?",
    repeatDate: "Repite tu orden del ",
    repeatBtn: "Si, repetir última compra",
    recommendedProduct: "Te recomendamos comprar también: "
  },
  wallet: {
    title: "Billetera",
    corporate: "Boletos corporativos",
    inputTitle: "¿Tienes un boleto corporativo?",
    inputPlaceholder: "Ingresa tu código de 16 digitos aquí",
    emptyTitle: "Ingresa tu boleto corporativo",
    emptyDescription:
      "Aún no has agregado Boletos corporativos a tu billetera, intenta canjear un código para tu boleto.",
    appliedTickets: "Boletos corporativos aplicados",
    info: "Para canjear tus boletos dirígete a cartelera y selecciona una película",
    stepsTitle: "Ingresa los 16 dígitos de tu boleto corporativo",
    valid: "Válido solo para películas",
    noApplicable: "No aplica cambios ni devoluciones",
    noAccumulate: "Socios MultiClub, no acumulan puntos ni visitas",
    applicable:
      "Aplica para CCI, Condado, Recreo, Scala, Pomasqui, Mall del Rio Cuenca, Batán, Milenium, Mall del Norte y Mall del Río Guayaquil",
    verify: "Verifica el formato, ciudad y vigencia antes de aplicar el boleto",
    haveTicket: "¿Tienes un boleto corporativo?",
    redeem: "Canjéalo aquí",
    notFound: "Boleto no encontrado",
    noRedeem: "Código Incorrecto",
    successRedeem: "Boleto ingresado con éxito",
    alreadyRedeemed: "Boleto canjeado o expirado",
    successRemove: "Boleto eliminado con éxito",
    errorRemove: "No se pudo eliminar el boleto",
    numberRedemptiosExceeded:
      "El número de redenciones de boletos excedió el límite",
    invalidTicket: "Boleto no aplica para esta función",
    VoucherRedeemed: "Boleto canjeado o expirado"
  },
  placeorder: {
    orderNotFounded: "No se encontró la orden",
    dontAcceptPaymentProvider: "No aceptamos pagos del proveedor",
    dontAcceptPaymentMethod: "No aceptamos este método de pago",
    penddingError: "Error al consultar si existe alguna orden pendiente",
    missingDataPayment:
      "No se pudo realizar el pedido debido a la falta de datos"
  },
  placeToPay: {
    errorValidatePayment: "Hubo un problema al validar tu pago",
    orderInfoNotFounded:
      "No se encontró la información de la orden. Por favor, intenta nuevamente.",
    placeToPayScriptNotLoaded: "El script de Place to Pay no ha sido cargado",
    noPaymentUrl: "No se ha podido obtener la URL de pago",
    nowProcessingPayment: "Ahora procesamos tu pago...",
    thisProcessCouldTake: "Este proceso podría tardar unos minutos...",
    paymentTimeout: "Estamos demorando en verificar tu pago",
    orderAlreadyProcessed: "Tu orden ya está procesada.",
    waitAndCheckOrderHistory:
      "Espera unos minutos y verifica tu historial de ordenes para confirmar si tu pago fue verificado",
    checkOrderHistory: "Verificar historial de ordenes"
  },
  talkShop: {
    finishMessage: "¡Listo! Aquí tienes el resumen y detalle de tu compra",
    submitOrderCode: "Presenta el código de tu orden",
    orQrCode: "o el código QR que te dejamos en el siguiente link :",
    ifLinkNotWork:
      "Si el enlace no funciona, por favor revisa tu correo electrónico"
  },
  otp: {
    sendOtp: "Hemos enviado un código de acceso a ",
    loginAccount: "para poder ingresar a tu cuenta",
    noSendOtp:
      "Si no has recibido nada a tu correo revisa tu bandeja de SPAM, o puedes",
    resendOtp: "Volver a solicitar el correo",
    continueGuest: "Continuar como invitado",
    successSendOtp:
      "El correo se ha enviado correctamente, verifique su bandeja de entrada",
    errorSendOtp: "No se pudo enviar el código, inténtalo nuevamente",
    errorCodeLength: "El código debe tener 4 dígitos"
  }
};

export default es;
