import { StoreInitialValues } from "@multicines/stores";

import { GlobalsStoreValues } from "./globals.store.types";

export const defaultValues: StoreInitialValues<GlobalsStoreValues> = {
  canShowDistanceAlert: false,
  showDistanceAlert: false,
  modalityFilter: "All",
  languageFilter: "All",
  theaterFilter: "All",
  formatFilter: "All",
  newOrder: undefined,
  disabledWeb: false,
  showHighTrafficModal: false
};
