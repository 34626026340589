import { Capacitor } from "@capacitor/core";
import { css } from "styled-components";
import "react-multi-carousel/lib/styles.css";

import CONSTANTS from "config/constants";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;
const isIOS = Capacitor.getPlatform() === "ios";

const base = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  .Modal__body {
    background-color: hsla(225, 15%, 10%, 1);
    display: flex;
    align-items: flex-start;
    justify-items: center;

    > * {
      height: 100%;
      width: 100%;
    }
  }

  html {
    font-size: 62.5%; //16px default to 10px
  }

  body {
    box-sizing: border-box;
    background-color: var(--palette-black-s15-l8);
    max-width: 100%;
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }

  :is(.TabsMenu__page, .Layout) > * {
    grid-template-rows: max-content 1fr max-content;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.8rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--palette-mid-blue-s15-l10);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--palette-mid-blue-s15-l20);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--palette-mid-blue-s15-l20);
  }

  .bubbles-animation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 50%;

    @media (max-width: ${tablet - 1}px) {
      width: 100%;
    }
  }

  .SettingsDrawer {
    &__separator {
      height: 0.8rem;
      background-color: var(--palette-gray-s0-l98);
      margin-left: -2.4rem;
      margin-right: -2.4rem;
    }

    &__info-card {
      padding: 1.6rem 0;
    }

    &__drawer {
      &__header {
        position: relative;
        padding-bottom: 4rem;

        &__title {
          text-align: center;
          font-family: Inter;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }

        &__cross {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }

      &__buttonGroupText {
        font-family: Inter;
        font-size: 1.2rem;
        line-height: 2rem;
        color: var(--palette-gray-s0-l40);
      }

      &__button {
        margin-top: auto;
        display: flex;
        justify-content: center;
        padding: 1rem 1.6rem;
        background: var(--palette-black-s0-l10);
        border-radius: 0.4rem;
        border: none;
        cursor: pointer;

        &__text {
          font-family: Inter;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: var(--palette-white);
        }
      }
    }

    &__buttonGroup {
      padding-top: 0.8rem;
      padding-bottom: 2.4rem;

      .Button--active {
        border: 0.1rem solid var(--palette-primary) !important;
        color: var(--palette-primary);
      }

      &__button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.6rem 2.4rem;
        border: 0.1rem solid var(--palette-gray-s0-l80);
        width: 11.2rem;
        height: 4.8rem;
        font-size: 1.6rem;

        &:first-child {
          border-top-left-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;
        }

        &:last-child {
          border-top-right-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }
      }
    }
  }

  .SearchBar__item--active {
    background-color: var(--palette-gray-s0-l98);
  }

  .CheckoutOrderStatus {
    &__modal {
      width: 100%;
      height: ${getMaxHeight};
    }
  }

  .BillboardFilters {
    &__modal {
      transform: translate(0, -50%) !important;
      left: 0 !important;
      width: min(${getMaxWidth()}, 40rem);
      background-color: var(--palette-mid-blue-s15-l8) !important;

      .Modal {
        &__body {
          ::-webkit-scrollbar-thumb {
            background-color: unset !important;
            border: unset !important;
          }
        }
      }
    }
  }

  .BillingDataModal {
    &__modal {
      background-color: var(--palette-dark-blue-s13-l11) !important;
      width: min(${getMaxWidth()}, 64rem);
      border-radius: 1.6rem !important;
      height: min(${getMaxHeight}, 72rem);

      .Modal {
        &__body {
          ::-webkit-scrollbar-thumb {
            background-color: unset !important;
            border: unset !important;
          }
        }
      }
    }
  }

  .DetailSeatsModal {
    &__modal {
      background-color: var(--palette-dark-blue-s13-l11) !important;
      width: min(${getMaxWidth()}, 64rem);
      border-bottom-left-radius: 1.6rem !important;
      border-bottom-right-radius: 1.6rem !important;

      &:first-child:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.4rem;
        background: linear-gradient(
          270deg,
          var(--palette-purple-s85-l45) -16%,
          var(--palette-pink-s75-l65) 48%,
          var(--palette-blue-s100-l45) 112%
        );
        top: 0;
        left: 0;
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;
      }
    }
  }

  .Modal {
    @media (max-width: ${tablet}px) {
      min-width: 72%;
    }

    @media (max-width: ${tablet - 1}px) {
      min-width: 100%;
    }

    &&& .Modal__close-icon {
      top: 2.4rem;
      right: 2.4rem;
    }
  }

  .SearchBar__wrapper,
  .SearchBar__btn {
    border-radius: 0.8rem;
  }

  .SearchBar__wrapper > .SearchBar__btn.SearchBar__btn-search {
    padding-left: 1.2rem;
    width: 3.6rem;
  }

  .Category,
  .Products {
    &__drawer {
      width: 100%;
      height: 100%;
      padding-top: 1.6rem;

      &__close {
        position: absolute;
        top: 2.4rem;
        right: 2rem;
        z-index: 2;
        cursor: pointer;
      }
    }

    &__results {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 1.6rem 2.4rem;
      background-color: var(--palette-white);
      z-index: 2;

      &&& &__button {
        font-family: inherit;
        width: 100%;
        justify-content: center;
        padding: 1.8rem 2.4rem;
      }
    }
  }

  .field {
    &&& label {
      color: var(--palette-white);
      padding-bottom: 0.4rem;
      padding-left: 1.2rem;
      padding-top: 1.2rem;
      font-size: 1.4rem;
      background-color: var(--palette-mid-blue-s15-l20);
      border-top-left-radius: 1.2rem;
      border-top-right-radius: 1.2rem;

      &.Checkbox__label {
        padding-bottom: 0;
      }
    }

    &&& input {
      padding: 0.4rem 1.2rem;
      font-size: 1.6rem;
      background-color: var(--palette-mid-blue-s15-l20);
      color: var(--palette-light-blue-s10-l60);
      border: none;
      font-weight: 600;
      border-radius: 0;
      border-bottom-left-radius: 1.2rem;
      border-bottom-right-radius: 1.2rem;
      outline: none;
      line-height: 3rem;

      &:hover {
        z-index: 1;
        border: none;
      }

      &::placeholder {
        font-size: 1.6rem;
        color: var(--palette-light-blue-s10-l60);
      }

      &:focus {
        z-index: 1;
        border: none;
        outline: none;
        color: var(--palette-light-blue-s10-l60);
        box-shadow: 0 0 0px 1000px var(--palette-mid-blue-s15-l20) inset;
      }

      &.PhoneNumberInput__input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:focus {
          margin-left: 0;
        }

        &:hover {
          margin-left: 0;
        }
      }

      &.DateInput__input {
        @media (max-width: ${mobile}px) {
          width: ${!isIOS ? "100%" : `min(${getMaxWidth()}, 36rem)`};
        }
      }
    }

    &&& figure {
      z-index: 1;
      background-color: var(--palette-mid-blue-s15-l20);
      right: 0.8rem;
      bottom: 0.8rem;

      @media (max-width: ${mobile}px) {
        right: 0.4rem;
        bottom: 0.4rem;
      }

      svg > path:nth-of-type(2) {
        fill: var(--palette-white);
      }
    }

    &&& .PasswordInput__container {
      border: none;
      outline: none;
      box-shadow: none;
      overflow: visible;
    }

    &&& .SelectCountry {
      background-color: var(--palette-mid-blue-s15-l20);
      border: none;
      padding: 0.4rem 1.2rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 1.2rem;

      & > * {
        border: none;
        color: var(--palette-light-blue-s10-l60);
        fill: var(--palette-light-blue-s10-l60);
      }
    }

    &&& .DocumentTypeSelect__selectContainer {
      background-color: var(--palette-mid-blue-s15-l20);
      border-bottom-left-radius: 1.2rem;
      border-bottom-right-radius: 1.2rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: none;
      color: var(--palette-light-blue-s10-l60);

      &:focus-within {
        border: transparent;
        box-shadow: 0rem 0rem 0rem 0.1rem transparent;
      }
    }
  }

  .border__item {
    border: 0.1rem solid var(--palette-black-s0-l10) !important;
    display: flex !important;
    align-items: center !important;
    padding: 1.2rem 1.6rem !important;
    min-height: 6.4rem !important;
    border-radius: 0.8rem !important;
  }

  .loading-shine {
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      background: linear-gradient(
        to right,
        transparent,
        rgba(180, 180, 180, 0.3),
        transparent
      );
      animation: load 1.5s cubic-bezier(0.4, 0, 0.3, 1) infinite;
      position: absolute;
      left: -100%;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  div.SettingsDrawer__drawer {
    padding: 1.6rem 2.4rem;
    padding-top: calc(1.6rem + env(safe-area-inset-top));
    min-width: 40rem;
    min-height: var(--sizes-page-maxHeight);
    max-height: calc(var(--sizes-page-maxHeight) - 5.7rem);

    @media (hover: none) {
      width: var(--sizes-page-maxWidth);
      min-height: calc(var(--sizes-page-maxHeight) - 5.7rem);
    }
  }

  .SettingsDrawer {
    &__buttonGroup {
      &__button {
        flex: 1;
      }
    }
  }

  .NotificationsContainer {
    & &__container {
      @media (max-width: ${tablet}px) {
        bottom: 7.2rem;
      }

      @media (max-width: ${mobile}px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        right: 0.6rem;
        left: 0.6rem;
      }
    }
  }

  .modalBackdrop {
    content-visibility: unset !important;
  }

  .modalUp {
    z-index: 99999999999 !important;
  }
`;

export default base;
