import { buildArtisnHeaders } from "@multicines/services";
import { useFetchLoyaltySubscription } from "@multicines/services";
import { usePostSnacksReservation } from "@multicines/services";
import { useGeoStore } from "@multicines/stores";
import { SNACKS_VENDOR } from "@multicines/utils";
import { getShoppingCartProductsByVendor } from "@multicines/utils";
import { validateShoppingCart } from "artisn/shopping-cart";
import { AxiosError } from "axios";
import React, { useMemo, useState } from "react";

import Styles from "./CartPayButton.styles";
import { CartPayButtonProps as Props } from "./CartPayButton.types";
import Button from "components/global/Button/Button";
import ShoppingCartNotifications from "components/global/notifications/ShoppingCartNotifications/ShoppingCartNotifications";
import { getAuth } from "config/artisn.config";
import { notify } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";
import { defaultFunction, shouldMock } from "utils/common.utils";
import { createErrorNotification } from "utils/notifications.utils";

const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = CONSTANTS.ARTISN;
const { API_URL } = CONSTANTS.API;
const { WITH_PURCHASE, WITH_DELIVERY } = CONSTANTS.FEATURE_FLAGS;
const { PLATFORM } = CONSTANTS.GENERAL;

const CartPayButton: React.FC<Props> = props => {
  const { empty, shoppingCart, setDisabled = defaultFunction } = props;
  const { setErrors = defaultFunction, className } = props;
  const { isConfirmedStore = true, pathName = "checkout" } = props;
  const { isFixedButton = false } = props;
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const authStore = { isAnonymous, uid };
  const { data: membership } = useFetchLoyaltySubscription(authStore);
  const selectedCoordinates = useGeoStore(state => state.selectedCoordinates);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const { mutateAsync: reserveSnacks, reset } =
    usePostSnacksReservation(selectedStore);
  const t = useI18n();
  const { updateShoppingCart } = useShoppingCart();
  const { navigateWithTalkShop } = useTalkShop();
  const [showStoreNotification, setShowStoreNotification] = useState(false);
  const [showNoCoverage, setShowNoCoverage] = useState(false);
  const [showMapCoordinates, setShowMapCoordinates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { memberLevelName, memberLevelId } = membership ?? {};

  const [, snackStores] = selectedStore ?? [];
  const { vendor } = snackStores ?? {};
  const { id: selectedSnackVendorId } = vendor ?? {};
  const snacks = useMemo(
    () =>
      getShoppingCartProductsByVendor(
        shoppingCart,
        selectedSnackVendorId ?? SNACKS_VENDOR.id
      ),
    [selectedSnackVendorId, shoppingCart]
  );
  const reserveSnacksPayload = useMemo(() => {
    if (!snacks) return;
    return snacks.map(snack => {
      const { amount, attributes, questionsAndAnswers } = snack;
      return {
        id: attributes.externalId,
        amount,
        questionsAndAnswers
      };
    });
  }, [snacks]);

  const showError = (message: string) => {
    createErrorNotification(message);
    setIsLoading(false);
  };

  const addAuthToken = async () => {
    const authToken = await getAuth.currentUser?.getIdToken();

    if (!updateShoppingCart) {
      setIsLoading(false);
      return;
    }

    await updateShoppingCart(prev => {
      return {
        ...prev,
        additional_info: {
          ...prev.additional_info,
          authToken,
          memberLevelName: memberLevelName ?? null,
          memberLevelId: memberLevelId ?? null
        }
      };
    });
  };

  const payButtonHandler = async () => {
    if (!shoppingCart || !uid || !isConfirmedStore) return;
    if (!Object.keys(shoppingCart.stores).length) return;
    setIsLoading(true);
    const { lat, lng } = selectedCoordinates ?? {};
    const validate = !shouldMock ? validateShoppingCart : () => undefined;
    const errors = await validate(
      {
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous!,
        accountId: ACCOUNT_ID,
        customerId: uid!,
        apiURL: API_URL,
        latitude: lat,
        longitude: lng
      },
      await buildArtisnHeaders(PLATFORM)
    );
    const { products, stores } = errors ?? {};
    stores?.forEach(store => {
      const { type } = store;
      if (type === "IS_OPEN" && stores.length === 1) {
        setShowStoreNotification(true);
      }
      if (type === "OUT_OF_COVERAGE" && WITH_DELIVERY) {
        setShowNoCoverage(true);
      }
    });
    const validationErrors = stores?.filter(store => {
      if (!WITH_DELIVERY) {
        return !(store.type === "OUT_OF_COVERAGE");
      }
      return store;
    });
    const hasErrors = !!products?.length || !!validationErrors?.length;
    if (hasErrors && !shouldMock) {
      setDisabled(true);
      setErrors(errors);
      setIsLoading(false);
      return;
    }
    addAuthToken();
    try {
      //INFO: this is snack workflow if is only ticket and no snack no call reserveSnacks
      if (reserveSnacksPayload) {
        await reserveSnacks(reserveSnacksPayload);
        reset();
      }

      navigateWithTalkShop(pathName);
      setIsLoading(false);
    } catch (e) {
      const isAxiosError = e?.isAxiosError;

      if (!isAxiosError) {
        showError(e?.message);
        return;
      }

      notify(e, "Reserve Snacks - Cart pay button");

      const { response, message: epMessage } = e as AxiosError;
      const { data, status } = response ?? {};
      const { error } = data ?? {};

      if (status === 503) {
        const service = error?.FailedDownstreamSystem;
        showError(
          `${t.common.vistaError}: ${JSON.stringify(service ?? error)}`
        );
        return;
      }

      if (typeof error === "string") {
        showError(error ?? epMessage);
        return;
      }

      if (Array.isArray(error)) {
        const [firstError] = error;
        showError(JSON.stringify(firstError));
        return;
      }

      if (typeof error === "object") {
        showError(JSON.stringify(error));
        return;
      }

      showError(epMessage);
    }
  };

  const isDisabledButton = empty || !WITH_PURCHASE || !isConfirmedStore;
  const isLoadingButton = isLoading;

  return (
    <Styles
      className={`CartPayButton ${className}`}
      isFixedButton={isFixedButton}
    >
      <Button
        mode="PRIMARY"
        className="Cart__summary__button"
        onClick={payButtonHandler}
        disabled={isDisabledButton}
        isLoading={isLoadingButton}
      >
        {t.common.goToPay}
      </Button>
      <ShoppingCartNotifications
        showMapCoordinates={showMapCoordinates}
        showNoCoverage={showNoCoverage}
        showClosedStore={showStoreNotification}
        setShowMapCoordinates={setShowMapCoordinates}
        setShowNoCoverage={setShowNoCoverage}
        setClosedStore={setShowStoreNotification}
      />
    </Styles>
  );
};

export default CartPayButton;
