// Common utility functions

import { PostShippingAddressPayload } from "@multicines/services";
import { OrderDetails, PaymentStatusPlaceToPay } from "@multicines/types";
import { getPlaceToPayReference, getPlaceToPayAmount } from "@multicines/utils";
import { Order, ShippingAddress, ShoppingCart } from "artisn/types";
import { Benefit, CartProduct, Product } from "artisn/types";
import { CategoryWithProducts } from "artisn/types";
import { DropdownProps } from "artisn-ui-react";
import dayjs from "dayjs";
import camelCase from "voca/camel_case";

import CONSTANTS from "config/constants";
import { ApiWarning } from "types/api.types";
import { BasicInternationalization } from "types/common.types";
import { CustomScriptAttributes } from "types/common.types";

const { BREAKPOINTS, API } = CONSTANTS;
const { MOCK_SERVICES } = API;
const { desktop } = BREAKPOINTS;

export const dropdownConfigs: Partial<DropdownProps> = {
  isFixed: true,
  target: ["focus"]
};

export const sortByDate = (a: string, b: string) => {
  const dateA = new Date(a).getTime();
  const dateB = new Date(b).getTime();

  if (dateA > dateB) {
    return 1;
  } else if (dateA < dateB) {
    return -1;
  } else {
    return 0;
  }
};

export const sortByProductDate = (a: CartProduct, b: CartProduct) => {
  return sortByDate(a.createdAt, b.createdAt);
};

export const joinProductsByCategories = (
  categories: CategoryWithProducts[]
) => {
  return categories.reduce((acc, category) => {
    return [...acc, ...category.products];
  }, [] as Product[]);
};

export const scrollToElement = (elementId: string, isModal?: boolean) => {
  const element = document?.getElementById?.(elementId);
  const navbar = document?.getElementById?.("navbar");
  const modifiers = document?.getElementById?.("modifiers");
  const modalHeader = document?.getElementById("modal-header");
  const navbarHeight = navbar?.clientHeight ?? 0;
  const modalHeaderHeight = modalHeader?.clientHeight ?? 0;
  const offsetTop = (element?.offsetTop ?? 0) - (!isModal ? navbarHeight : 0);
  if (isModal) {
    modifiers?.scrollTo({
      top: offsetTop - modalHeaderHeight,
      behavior: "smooth"
    });
    return;
  }
  window.scrollTo({ top: offsetTop, behavior: "smooth" });
};

export const defaultFunction = () => {};

export const removeQueryParam = (path: string, queryParam: string) => {
  const urlBase = path.split("?")?.[0];
  const queryParams = path.split("?")?.[1];
  if (!queryParams) {
    return urlBase;
  }

  const paramsArray = queryParams.split("&");
  const newQueryParams = paramsArray
    .filter(param => !param.includes(`${queryParam}=`))
    .join("&");

  if (!newQueryParams) {
    return urlBase;
  }

  return `${urlBase}?${newQueryParams}`;
};

export const getFullPath = () => {
  if (typeof window === "undefined") return;

  return window.location.origin + window.location.pathname;
};

export const getMapSize = (
  windowWidth: number,
  windowHeight: number,
  padding = 0
) => {
  let width = windowWidth - padding;
  let height = 160;
  if (windowWidth >= desktop) {
    width = 500;
    height = 180;
  }
  return { width, height };
};

// This value should not be modified if you want to disable mocks
// To disable mocks, go to config/constants.ts
export const shouldMock =
  process.env.NEXT_PUBLIC_ENV === "production" ? false : MOCK_SERVICES;

export const isTouchScreenDevice = () => {
  if (typeof window === "undefined") return;
  return "ontouchstart" in window || navigator.maxTouchPoints;
};

export const encode64 = (string: string) =>
  Buffer.from(string, "binary").toString("base64");

export const getBenefitProductId = (
  temporalBenefit?: Benefit,
  cartBenefit?: Benefit
) => {
  const { type, award } = temporalBenefit ?? cartBenefit ?? {};
  return type === "PRODUCT" && Array.isArray(award)
    ? award[0].productId.toString()
    : "";
};

export const loadScript = (attributes: CustomScriptAttributes) => {
  const { id } = attributes;
  const existingScript = document.getElementById(id);
  if (existingScript) return;
  const script = document.createElement("script");
  Object.entries(attributes).forEach(entry => {
    const [key, value] = entry;
    const realKey = key.toLocaleLowerCase() as keyof CustomScriptAttributes;
    // @ts-ignore The DOM script attributes can't be typed
    script[realKey] = value;
  });
  document.body.appendChild(script);
};

export const removePayPhoneButton = () => {
  const payPhoneButtonContainer = document.getElementById("pp-button");
  while (payPhoneButtonContainer?.firstChild) {
    payPhoneButtonContainer.removeChild(payPhoneButtonContainer.firstChild);
  }
};

export const removePlaceToPayScript = () => {
  const script = document.getElementById("placeToPay");
  script?.remove();
};

export const sanitizeQueryParams = (query: NodeJS.Dict<string | string[]>) => {
  const newQuery: Record<string, string | undefined> = {};
  Object.entries(query).forEach(entry => {
    const [key, value] = entry;
    if (typeof value === "string") newQuery[key] = value;
  });
  return newQuery;
};

export const removeDuplicates = <T>(items: T[]) => {
  return Array.from(new Set(items));
};

export const transformObjectKeysToCamelCase = <T extends typeof Object, U>(
  params: T
) => {
  return Object.entries(params).reduce<U>((acc, params) => {
    const [key, value] = params;
    const newKey = camelCase(key as string);
    return {
      ...acc,
      [newKey]: value
    };
  }, {} as U);
};

export const getNameAndLastName = (completeName: string) => {
  const [name, ...rest] = completeName.split(" ");
  return { name, lastName: rest.join(" ") };
};

export const mapAPIErrors = (warnings: ApiWarning[] | undefined): string[] => {
  if (!warnings) return [];
  return warnings.map(warning => warning.value);
};

export const getWarningField = (
  warnings: ApiWarning[] | undefined
): string[] => {
  if (!warnings) return [];
  return warnings.map(warning => warning.field);
};

export const getFirebaseAuthErrorMessage = (
  t: Record<string, string>,
  error?: any
) => {
  const { code, message = "" } = error ?? {};
  if (!code) return message;
  switch (code) {
    case "auth/wrong-password":
      return t.wrongPassword;
    case "auth/user-not-found":
      return t.userNotFound;
    case "auth/too-many-requests":
      return t.tooManyRequests;
    case "auth/email-already-in-use":
      return t.emailAlreadyInUse;
    case "auth/network-request-failed":
      return t.networkRequestFailed;
    case "auth/operation-not-allowed":
      return t.operationNorAllowed;
    case "auth/invalid-action-code":
      return t.invalidActionCode;
    case "auth/account-exists-with-different-credential":
      return t.accountExistsWithDifferentCredential;
    default:
      return message;
  }
};

export const getPostShippingAddress = (
  selectedShippingAddress: ShippingAddress
) => {
  const { country, lat, lng, livingPlace } = selectedShippingAddress;
  const { mainStreet, nickname, number, reference } = selectedShippingAddress;
  const { numberContactAddress, secondaryStreet } = selectedShippingAddress;
  const { updatedAt, default: defaultAddress } = selectedShippingAddress;
  const postShippingAddressPayload: PostShippingAddressPayload = {
    country,
    lat,
    lng,
    livingPlace,
    mainStreet,
    nickname,
    number,
    numberContactAddress,
    reference,
    secondaryStreet,
    updatedAt,
    default: defaultAddress
  };
  return postShippingAddressPayload;
};

export const getPlaceToPayMessage = (
  t: BasicInternationalization,
  formatByCurrency: (value: number | null | undefined) => string,
  status?: PaymentStatusPlaceToPay,
  order?: Order
) => {
  const reference = getPlaceToPayReference(order);
  const orderAmount = getPlaceToPayAmount(order);
  const amount = formatByCurrency(+orderAmount);
  switch (status) {
    case "APPROVED":
      return ` ${t.purchaseStatus.p2pSuccess.reference} ${reference ?? ""} ${
        t.purchaseStatus.p2pSuccess.value
      } ${amount ?? ""} ${t.purchaseStatus.p2pSuccess.approved}`;
    case "PENDING":
      return ` ${t.purchaseStatus.p2pPending.reference} ${reference ?? ""}  ${
        t.purchaseStatus.p2pPending.value
      } ${amount ?? ""}`;
    case "REJECTED":
      return ` ${t.purchaseStatus.p2pRejected.reference} ${reference ?? ""}  ${
        t.purchaseStatus.p2pRejected.value
      } ${amount ?? ""}  ${t.purchaseStatus.p2pRejected.rejected}`;
    default:
      return "";
  }
};

export const getPurchaseStatusMessage = (
  t: BasicInternationalization,
  formatByCurrency: (value: number | null | undefined) => string,
  status?: PaymentStatusPlaceToPay,
  order?: Order
) => {
  return `${getPlaceToPayMessage(t, formatByCurrency, status, order)}`;
};

export const isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
};

export const isIOS = () => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

const LOCAL_ORDER = "LOCAL_ORDER";
const LOCAL_SHOPPING_CART = "LOCAL_SHOPPING_CART";

export const saveLocalOrder = (order: OrderDetails | undefined) => {
  if (!order) {
    throw new Error("Missing order");
  }
  localStorage.setItem(LOCAL_ORDER, JSON.stringify(order));
};

export const saveLocalShoppingCart = (
  shoppingCart: ShoppingCart | undefined
) => {
  if (!shoppingCart) {
    throw new Error("Missing shoppingCart");
  }
  localStorage.setItem(LOCAL_SHOPPING_CART, JSON.stringify(shoppingCart));
};

export const getLocalValues = () => {
  let order: OrderDetails | undefined;
  const localOrder = localStorage.getItem(LOCAL_ORDER);
  if (localOrder) {
    order = JSON.parse(localOrder);
  }

  let shoppingCart: ShoppingCart | undefined;
  const localShoppingCart = localStorage.getItem(LOCAL_SHOPPING_CART);
  if (localShoppingCart) {
    shoppingCart = JSON.parse(localShoppingCart);
  }

  return {
    order,
    shoppingCart
  };
};

export const clearLocalValues = () => {
  localStorage.removeItem(LOCAL_ORDER);
  localStorage.removeItem(LOCAL_SHOPPING_CART);
};

export const transformDate = (orderDate: string) => {
  dayjs.locale("en");

  return dayjs(orderDate, "DD MMM YYYY").format("YYYY-MM-DD");
};

export const transformDateTime = (orderTime: string) => {
  dayjs.locale("en");

  return dayjs(orderTime, "HH:mm a").format("HH:mm");
};
